import cn from "classnames";
import PropTypes from "prop-types";
import { BLOGPOST_CARD_TYPES } from "const";

import Placeholder from "../Placeholder";

export default function BlogpostPlaceholder({ className, delay, cardType }) {
  return (
    <div className={cn("border-l-2 border-gray-500 pl-20", className)}>
      {cardType !== BLOGPOST_CARD_TYPES.VIEW_COMPACT && (
        <div className="mb-8 flex md:mb-20">
          <Placeholder className="h-50 w-50 flex-shrink-0 rounded-full border" animate delay={delay + 100} />
          <div className="ml-10 flex items-center md:flex-col md:items-start md:justify-between">
            <Placeholder className="rounded-2" height={20} width={150} animate delay={delay + 200} />
            <Placeholder className="hidden rounded-2 md:block" height={18} width={100} animate delay={delay + 200} />
          </div>
        </div>
      )}
      <Placeholder className="mb-20 rounded-2 md:hidden" height={18} width={100} animate delay={delay + 200} />
      <Placeholder
        className="mb-30 h-[2rem] rounded-2 md:h-[3rem]"
        height={25}
        width={300}
        animate
        delay={delay + 300}
      />
      <div className="mb-30 space-y-10 text-18 leading-relaxed">
        <Placeholder className="w-full rounded-2" height={18} animate delay={delay + 400} />
        <Placeholder className="w-[80%] rounded-2" height={18} animate delay={delay + 400} />
        <Placeholder className="w-[90%] rounded-2" height={18} animate delay={delay + 400} />
        <Placeholder className="w-[85%] rounded-2" height={18} animate delay={delay + 400} />
      </div>
      <div className="flex flex-col md:flex-row md:justify-between">
        <Placeholder className="rounded-2" height={22} width={90} animate delay={delay + 500} />
        <div className="order-first mb-20 ml-0 flex items-center space-x-15 sm:order-last sm:mb-0 sm:ml-auto md:justify-start">
          <Placeholder className="rounded-2" height={22} width={50} animate delay={delay + 500} />
          <Placeholder className="rounded-2" height={22} width={50} animate delay={delay + 500} />
          <Placeholder className="rounded-2" height={22} width={50} animate delay={delay + 500} />
        </div>
      </div>
    </div>
  );
}

BlogpostPlaceholder.propTypes = {
  cardType: PropTypes.oneOf(Object.values(BLOGPOST_CARD_TYPES)),
  className: PropTypes.string,
  delay: PropTypes.number,
};

BlogpostPlaceholder.defaultProps = {
  cardType: BLOGPOST_CARD_TYPES.VIEW_FULL,
  className: null,
  delay: null,
};
