import PropTypes from "prop-types";
import { AUTH_MODAL_STATE, AVATAR_SIZE_PREFIX, getProfileUrl, REG_PROMPT_TRIGGERS } from "const";
import { formatDate } from "utils/date";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import LikeButton from "components/LikeButton";
import { Button, Icon, Link } from "components/basic";
import { useStore } from "store";
import ShareButtons from "components/ShareButtons";
import Avatar from "components/Avatar";
import cn from "classnames";
import ElementStats from "../BlogpostRenderer/Elements/ElementStats";

function CardFooter({ publishedAt, stats, likes, bottomControls, ownerProfile, sourceUrl, shareId, shareType }) {
  const { ui, user, pages, profiles } = useStore();

  useEffect(() => {
    if (!likes || likes?.amountOfLikes === 0) return;

    stats.setAmountOfLikes(likes.amountOfLikes);
  }, [likes, likes?.amountOfLikes, stats]);

  return (
    <div>
      <div className="flex flex-col md:flex-row md:items-start md:justify-between">
        <div className="flex flex-col">
          <span className="text-13 leading-15 text-new-gray-700">
            {publishedAt ? formatDate(publishedAt, "MMMM D, YYYY, HH:mm") : "Draft"}
          </span>
        </div>

        <ElementStats
          styles={{ listStyles: "order-first flex ml-0 mb-20 sm:mb-0 sm:ml-auto sm:order-last" }}
          stats={[
            { name: "amountOfViews", value: stats.totalViews, description: "Views" },
            { name: "amountOfComments", value: stats.comments, description: "Comments" },
            { name: "amountOfLikes", value: stats.amountOfLikes, description: "Likes" },
          ]}
          likes={likes}
          sourceUrl={sourceUrl}
        />
      </div>
      {bottomControls && (
        <div className="mt-20 flex w-full items-center">
          {ownerProfile && (
            <div className="mr-auto flex items-center">
              <Link
                href={getProfileUrl(ownerProfile.id, ownerProfile.username)}
                beforeNav={() => pages.profilePage.mount(ownerProfile.id, ownerProfile.name)}
                rel="canonical"
              >
                <Avatar profile={ownerProfile} size={AVATAR_SIZE_PREFIX.EXTRA_SMALL} scale={90} />
              </Link>
              <Link
                href={getProfileUrl(ownerProfile.id, ownerProfile.username)}
                beforeNav={() => pages.profilePage.mount(ownerProfile.id, ownerProfile.username)}
                className="ml-10 text-15 font-semibold hover:text-new-blue-100"
                rel="canonical"
              >
                {ownerProfile.name || ownerProfile.username}
              </Link>
            </div>
          )}
          <div className="flex items-center space-x-10">
            {user.isLoggedIn ? (
              likes && (
                <LikeButton
                  likes={likes}
                  minified
                  minifiedSize={15}
                  color="blue"
                  className="rounded-6 shadow-page-title"
                />
              )
            ) : (
              <Button
                className="group rounded-6 p-5 shadow-page-title hover:bg-new-blue-100/70 sm:p-8"
                onClick={() => {
                  ui.authModal.setState(AUTH_MODAL_STATE.REGISTER_TO_CONTINUE, {
                    type: shareType,
                    id: shareId,
                    action: REG_PROMPT_TRIGGERS.LIKE,
                  });
                  ui.authModal.setOpen(true);
                }}
                aria-label="Like"
              >
                <Icon icon="heart-empty" className="h-17 w-17 group-hover:text-white-100" />
              </Button>
            )}
            <ShareButtons minified={ui.isMobile} entityId={shareId} entityType={shareType} />
            {ownerProfile && user.isLoggedIn && profiles.currentUserProfile?.id !== ownerProfile.id && (
              <Button
                className={cn(
                  "flex flex-shrink-0 items-center justify-center rounded-6 p-5 text-13 shadow-page-title transition-colors hover:text-white-100 md:p-10",
                  ownerProfile.isFollowed ? "hover:bg-red-700" : "hover:bg-new-blue-100/60"
                )}
                onClick={
                  ownerProfile.isFollowed
                    ? () => profiles.currentUserProfile.unfollow(ownerProfile.id)
                    : () => profiles.currentUserProfile.follow(ownerProfile.id)
                }
              >
                {ui.isMobile ? (
                  <Icon
                    icon={ownerProfile.isFollowed ? "person-minus" : "person-plus"}
                    className="h-17 w-17 fill-current"
                  />
                ) : (
                  <>
                    {ownerProfile.isFollowed ? "Unfollow" : "Follow"}
                    <Icon
                      icon={ownerProfile.isFollowed ? "person-minus" : "person-plus"}
                      className="ml-7 h-12 w-12 fill-current"
                    />
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

CardFooter.propTypes = {
  stats: PropTypes.shape({
    totalViews: PropTypes.number,
    comments: PropTypes.number,
    amountOfLikes: PropTypes.number,
    setAmountOfLikes: PropTypes.func,
  }),
  likes: PropTypes.shape({ amountOfLikes: PropTypes.number }),
  publishedAt: PropTypes.string,
  bottomControls: PropTypes.bool,
  ownerProfile: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    name: PropTypes.string,
    avatarFilename: PropTypes.string,
    isFollowed: PropTypes.bool,
  }),
  sourceUrl: PropTypes.string,
  shareId: PropTypes.number.isRequired,
  shareType: PropTypes.oneOf(["artwork", "board", "blogpost", "collection"]).isRequired,
};

CardFooter.defaultProps = {
  stats: { totalViews: 0, comments: 0, amountOfLikes: 0, setAmountOfLikes: () => {} },
  likes: { amountOfLikes: 0 },
  publishedAt: null,
  bottomControls: false,
  ownerProfile: {},
  sourceUrl: null,
};

export default observer(CardFooter);
