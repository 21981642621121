import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { getBlogpostUrl, BLOGPOST_CARD_TYPES, URL, ENTITY_TYPES, AUTH_MODAL_STATE, REG_PROMPT_TRIGGERS } from "const";
import { Link, Button, Icon } from "components/basic";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BlogpostPlaceholder from "components/placeholders/BlogPlaceholders/BlogpostPlaceholder";
import cn from "classnames";
import CardHeader from "components/CardHeader";
import { useRouter } from "next/router";
import Blurhash from "components/basic/Blurhash";
import ae from "lib/analytics";
import CardFooter from "./CardFooter";

function BlogpostCard(props) {
  const {
    id,
    className,
    owner,
    publishedAt,
    category,
    blog,
    name,
    preview,
    stats,
    likes,
    cardType,
    editable,
    removable,
    isProcessingAction,
    status,
    pinned,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ownerProfile, setOwnerProfile] = useState(null);

  const router = useRouter();

  const { pages, profiles, user, ui } = useStore();

  useEffect(() => {
    async function loadFollowInfo() {
      try {
        setIsLoading(true);

        const resp = await profiles.loadProfile(owner.id);
        await resp.loadIsFollowed();
        setOwnerProfile(resp);

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(true);
      }
    }

    loadFollowInfo();
  }, [profiles, owner.id]);

  if (isLoading) return <BlogpostPlaceholder cardType={cardType} className="mb-20 w-full" />;

  if (error)
    return (
      <div className="rounded-5 border border-gray-400 p-20 font-light shadow-sm">This publication is unavailable</div>
    );

  const isGIF = preview?.url?.split(".").pop().toLowerCase() === "gif";

  return (
    ownerProfile && (
      <div className={cn("w-full rounded-6", pinned && "bg-gradient-to-r from-new-purple-100 to-new-blue-100 p-2")}>
        <div
          className={cn(
            "w-full space-y-20 rounded-5 bg-white-100 p-20",
            className,
            isProcessingAction && "relative z-0 overflow-hidden"
          )}
        >
          <CardHeader
            ownerProfile={ownerProfile}
            publishedAt={publishedAt}
            isDraft={status !== "PUBLISHED"}
            editable={editable}
            editFn={() => {
              router.push({ pathname: URL.POST_EDITOR, query: { editing: id } });
            }}
            blogpostId={id}
            removable={removable}
            pinned={pinned}
            likes={likes}
          />
          {isProcessingAction && (
            <div
              className="absolute left-0 top-0 z-10 h-full w-full animate-pulse rounded-6 bg-white-1000/80"
              style={{ marginTop: 0 }} // remove unnecessary margin from space-y-20
            />
          )}
          <Link
            href={getBlogpostUrl(blog.id, id, name)}
            beforeNav={() => pages.blogpostPage.mount(id)}
            className="inline-flex text-30 font-semibold text-new-gray-800 sm:text-35"
            rel="canonical"
          >
            <span className="no-underline">{name}</span>
          </Link>
          {(preview?.crop?.url || preview?.url) &&
            (user.userData.settings.hideSensitiveContent && preview.sensitiveContent ? (
              <Button
                onClick={() => {
                  if (!user.isLoggedIn) {
                    ui.authModal.setState(AUTH_MODAL_STATE.REGISTER_TO_CONTINUE, {
                      type: ENTITY_TYPES.ARTWORK,
                      id,
                      action: REG_PROMPT_TRIGGERS.SENSITIVE_CHANGE,
                    });
                    ui.authModal.setOpen(true);
                    return;
                  }

                  ui.sensitiveContentModal.setOpen(true);
                  ae.sensitive.open(user.userData.settings.hideSensitiveContent, ENTITY_TYPES.ARTWORK, id);
                }}
                className="relative min-h-[18rem] w-full overflow-hidden rounded-6 bg-gray-100/10 text-white-100 md:min-h-[40rem]"
              >
                <Blurhash hash={preview.blurhash} className="absolute h-full w-full" />
                <div className="z-10 flex flex-col items-center space-y-15 text-white-100">
                  <Icon icon="alert" className="h-50 w-50 flex-shrink-0" />
                  <p className="block max-w-[22rem] text-center text-12 leading-tight">
                    Following content may not be suitable for some audiences.{" "}
                  </p>
                  <p className="block font-semibold hover:underline">View</p>
                </div>
              </Button>
            ) : (
              <Link
                data-blogpost-link
                href={getBlogpostUrl(blog.id, id, name)}
                beforeNav={() => pages.blogpostPage.mount(id)}
                className="inline-flex w-full"
                rel="canonical"
              >
                <div className="relative min-h-[18rem] w-full rounded-6 bg-gray-100/10 md:min-h-[40rem]">
                  <img
                    src={preview?.crop?.url ? preview.crop.url : preview.url}
                    className={cn(
                      "absolute h-full w-full rounded-6 opacity-0 transition-opacity",
                      isGIF ? "object-contain" : "object-cover"
                    )}
                    onLoad={e => {
                      e.target.classList.remove("opacity-0");
                    }}
                    alt={name}
                  />
                </div>
              </Link>
            ))}
          {preview?.text && <p className="text-18 leading-relaxed">{preview.text}</p>}
          {!pinned && (
            <CardFooter
              cardType={cardType}
              publishedAt={publishedAt}
              category={category}
              stats={stats}
              likes={likes}
              sourceUrl={getBlogpostUrl(blog.id, id, name)}
              shareId={id}
              shareType={ENTITY_TYPES.BLOGPOST}
            />
          )}
        </div>
      </div>
    )
  );
}

BlogpostCard.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  owner: PropTypes.shape({ id: PropTypes.number }).isRequired,
  blog: PropTypes.shape({ id: PropTypes.number }).isRequired,
  publishedAt: PropTypes.string,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  preview: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    crop: PropTypes.shape({ url: PropTypes.string }),
    sensitiveContent: PropTypes.bool,
    blurhash: PropTypes.string,
  }),
  stats: PropTypes.shape(),
  cardType: PropTypes.oneOf(Object.values(BLOGPOST_CARD_TYPES)),
  likes: PropTypes.shape(),
  editable: PropTypes.bool,
  removable: PropTypes.bool,
  isProcessingAction: PropTypes.bool,
  status: PropTypes.string,
  pinned: PropTypes.bool,
};

BlogpostCard.defaultProps = {
  className: null,
  preview: {},
  stats: {},
  likes: {},
  publishedAt: null,
  editable: false,
  removable: false,
  isProcessingAction: false,
  status: "DRAFT",
  cardType: BLOGPOST_CARD_TYPES.VIEW_FULL,
  pinned: false,
};

export default observer(BlogpostCard);
